*{
    box-sizing:border-box;
    margin: 0;
    padding: 0;
    font-family:'PT Sans', sans-serif;
    
}

.home,
.products {
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about-us {
    /* background-image: url('components/images/grains-img-1.jpg'); */
    font-family: 'Playfair display',bold;
    background-color: azure;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    height: 3;
    align-items: center;
    justify-content: center;
    color: #000000;
    height: 10vh;
    font-size: 30px;  
    /* border-bottom: 1px solid rgb(3, 5, 7); */
}

.products {
    background-image: url('components/images/img-3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 50px;
}

.contact-us {
    /* background-image: url('components/images/img-2.jpg'); */
    display:flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000000;
    font-size: 50px;
    font-family: 'Times New Roman', Times, serif;
}  
