.contact-us{
    font-family: 'Playfair display',bold;
    background-color: rgba(125, 169, 202, 0.478);
    height: 10vh;
    font-size: 30px;  
    /* border-bottom: 1px solid rgb(3, 5, 7); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    height: 3;
    align-items: center;
    justify-content: center;
    color: black;
}
.contact-sub {
    display: flex;
    flex-direction: column;
    /* background-color: azure; */
    background-image: url('./images/bg-img-1.jpg') ;

}
.input-areas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: flex-start;
    text-align: start;
}
.area{
    display: block;
    padding: 5px;
    margin:5px;
}
.input-area-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin:20px;
}
.input-areas-que{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
}
.input-area-q{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.contact-input-q::placeholder{
    color:#b1b1b1;
    border: #000000;
}
.contact-input-q{
    padding:8px 20px;
    border-radius: 4px;
    outline:none;
    width: 520px;
    height: 100px;
    margin-bottom: 30px;
}
.contact-input::placeholder {
    color:#b1b1b1;
    border: #000000;
}
.contact-input {
    padding: 8px 15px;
    border-radius: 4px;
    margin-right:10px;
    outline:none;
    width: 500px;
    height: 50px;
    /* margin-top: 30px; */
}

.input-area-btn{
    display: flex;
    justify-content: right;
    padding: 20px 20px;
    margin-right:1%;
}
.contact-us-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightblue;
    /* background-color: grey; */
    /* color: #fff; */
}

.phone-sub,
.email-sub,
.address-sub{
    display: flex;
    flex-direction:column;
    justify-content: left;
    align-items: left;
    width:450px;
    margin-left:20px;
    margin-right: 20px;
    padding: 20px;
}

.btn--primary { 
    padding: 10px 20px;
    margin-right:70px;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: rgb(28, 27, 27);
    border:1px solid #000000
}
.btn--primary:hover{
    background-color: white;
    color: black;
    
}

.text-danger{
    color: red;
    font-weight: 50;
    font-size: 12px;
    font-family: monospace;
    display: flex;
    margin-bottom: 10px;
}
@media screen and (max-width:768px) {
    .contact-us{
        font-size: 40px;
    }
    .contact-input{
        width: 300px;
    }
    .contact-input-q{
        width: 300px;
    }
    .input-area-q{
        display: flex;
        flex-direction: column;
        margin:0;
    }
    .input-areas-que{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .input-areas{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .input-area-wrapper{
        display: flex;
        flex-direction: column;
        margin:0;
    }
    .contact-sub{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-us-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}