.navbar {
    /* background: linear-gradient(90deg, rgb(9, 52, 100) 0%, rgb(50, 85, 125) 100%); */
    background: white;
    height: 80px;
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-around; */
    border-bottom: 1px solid black;
    /* align-items: center; */
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }


  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo  {
    color: #000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-family: Domine,bold;
  }
  
  /* .faShip {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  } */
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60%;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
    margin-left: 1rem;
    margin-right: 1rem;
    /* width: 120px; */
  }
  
  .nav-links {
    color: #000;
    display:flex;
    align-items: center;
    text-decoration:solid;
    padding: 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 3px solid #000;
    transition: all 0.2s ease-out;
  }
  
  .faBars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .NavbarItems {
      position: relative;
    }
    /* .navbar{
      height: 120px;
    } */
  
    .nav-menu {
      display: table;
      flex-direction: column;
      width: 100%;
      height: 90%;
      position:absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: rgb(144, 185, 233);;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-section{
      width:100px
    }
    /* .nav-section > p */
    .nav-links {
      text-align:center;
      text-wrap:nowrap;
      padding: 1.5rem;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      display: flex;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 5;
      border:1px solid;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0px;
      display: flex;
      /* align-items: flex-start; */
      left: 10px;
      transform: translate(0%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .faXmark {
      color: #ffffff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2 rem auto;
      border-radius: 4px;
      width: 90%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #ffffff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }