.vidtag {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background:url('images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px ;
}

.hero-para > p {
    margin: 8px;
    color: #fff;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}
.hero-btns .btn {
    margin: 6px;
}

@media screen and (max-width: 900px) { 
    
    .hero-container > h1{
        font-size: 70px;
        margin-top: -150px;
    }
}
@media screen and (max-width: 768px) { 
    .hero-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hero-para > p{
        display: flex;
        flex-direction: column;
        font-size: 20px;
        text-align: center;
    }
    .hero-container > h1{
        font-size: 50px;
        margin-top: -100px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
        /* width:20vw; */
    }
    
    .btn {
        width: 100%;
    }
}