:root {
    --primary:#ffffff
}
.btn {
    padding: 12px 28px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}
.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 14px;
    font-family: Georgia, 'Times New Roman', Times, serif ;
}
.btn--large {
    padding: 12px 26px;
    font-size: 16px;
}
.btn--medium:hover, .btn--large:hover {
    background: #ffffff;
    color: #242424;
    transition: all 0.3s ease-out;
}

@media (max-width: 768px) {
   .btn {
    padding: 12px 28px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    }
    .btn--primary {
        background-color: var(--primary);
        color: #242424;
        border: 1px solid var(--primary);
    }
    .btn--outline {
        background-color: transparent;
        color: #fff;
        width:100px;
        padding: 10px 15px;
        border: 1px solid var(--primary);
        transition: all 0.3s ease-out;
    }
    .btn--medium{
        width:100%;
    }
}
